@keyframes logo_enter_left {
  from {
    transform: translateX(-50vw);
  }
  to {
    transform: translateX(0);
  }
}
@keyframes logo_enter_right {
  from {
    transform: translateX(50vw);
  }
  to {
    transform: translateX(0);
  }
}
@keyframes logo_appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.home-logo {
  min-height: calc(100vh - 9rem);
  display: grid;
  align-items: center;
  margin-bottom: 3rem;
  --logo-duration: 4s;
  --logo-half-width: 4rem;
  --logo-max-half-width: 4rem;
}
.home-logo h1 {
  font-size: calc(var(--logo-half-width) / 4);
  display: grid;
  grid-template-columns: 1fr auto 1fr;
}
.home-logo h1 .titleLeft {
  text-align: right;
}
.home-logo h1 .titleRight {
  text-align: left;
}
.home-logo .welcome-blurb {
  text-align: center;
  display: block;
  margin-bottom: 2rem;
  font-size: 1.5rem;
  font-size: calc(var(--logo-half-width) / 6);
}
.logo-half {
  display: inline-block;
}
.icon-container {
  margin: auto;
  width: calc(var(--logo-half-width) * 2 + var(--logo-half-width) / 10);
  max-width: calc(var(--logo-max-half-width) * 2 + var(--logo-max-half-width) / 10);
}
.logo-half {
  animation-timing-function: cubic-bezier(0.3, -0.01, 0.82, 0.19);
  animation-duration: var(--logo-duration);
  animation-delay: 0.1s;
  animation-fill-mode: forwards;
  width: var(--logo-half-width);
  max-width: var(--logo-max-half-width);
}
.logo-half.left {
  transform: translateX(-50vw);
  animation-name: logo_enter_left;
}
.logo-spacer {
  width: calc(var(--logo-half-width) / 10);
  max-width: calc(var(--logo-max-half-width) / 10);
  display: inline-block;
}
.logo-half.right {
  transform: translatex(50vw);
  animation-name: logo_enter_right;
}
.home-logo h1,
.home-logo .welcome-blurb {
  opacity: 0;
  animation-name: logo_appear;
  animation-duration: 0.75s;
  animation-delay: calc(var(--logo-duration) + 0.15s);
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.3, -0.01, 0.82, 0.19);
}

/* adjustment for heights */
@media screen and (min-height: 480px) {
  .home-logo {
    --logo-half-width: 6rem;
  }
}
@media screen and (min-height: 600px) {
  .home-logo {
    --logo-half-width: 8rem;
  }
}
@media screen and (min-height: 800px) {
  .home-logo {
    --logo-half-width: 14rem;
  }
}
@media screen and (min-height: 1100px) {
  .home-logo {
    --logo-half-width: 16rem;
  }
}
/* adjustments for widths */
@media screen and (max-width: 1100px) {
  .home-logo {
    --logo-max-half-width: 16rem;
  }
}
@media screen and (min-width: 800px) {
  /* this is super hacky */
  .home-logo h1 .titleSpace::after {
    content: "-";
    opacity: 0;
  }
}
@media screen and (max-width: 800px) {
  .home-logo {
    --logo-max-half-width: 16rem;
  }
  .home-logo h1 {
    display: block;
  }
}
@media screen and (max-width: 600px) {
  .home-logo {
    --logo-max-half-width: 14rem;
  }
}
@media screen and (max-width: 500px) {
  .home-logo {
    --logo-max-half-width: 10rem;
  }
}
@media screen and (min-width: 800px) {
  .home-logo {
    --logo-max-half-width: 20rem;
  }
}
