#footer {
  background-color: var(--theme-color-primary);
  border-top: 0.25rem solid var(--theme-color-secondary);
  color: #fff;
  padding: 0.5rem;
  text-align: center;
  justify-self: flex-end;
}

@media screen and (max-width: 500px) {
  #footer {
    text-align: left;
  }
}
