@keyframes subPageFadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.subpage-section.show {
  animation: subPageFadeIn 0.5s ease-in-out;
  animation-fill-mode: forwards;
}

.subpage-section {
  display: flex;
  flex-direction: column;
  padding: 3rem 0;
  align-items: center;
  position: relative;
  opacity: 0;
}

.subpage-section .content {
  display: grid;
  grid-template-rows: auto auto auto;
}

.subpage-section .content[data-orientation="left"] {
  grid-template-columns: 3fr 4fr;
}
.subpage-section .content[data-orientation="right"] {
  grid-template-columns: 4fr 3fr;
}

.subpage-section .detect-visibility {
  position: absolute;
  top: 25%;
}

.text-container {
  display: flex;
  flex-direction: column;
}

.text-container p {
  line-height: 1.5rem;
}

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-container img {
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
  border-radius: 0.5rem;
}

.subpage-section .subpage-title {
  text-transform: uppercase;
  grid-row-start: 1;
  margin: 0;
  margin-bottom: 0.25rem;
  font-size: 2.25rem;
}

[data-orientation="right"] .subpage-title {
  grid-column-start: 1;
}
[data-orientation="left"] .subpage-title {
  grid-column-start: 2;
}

.text-container {
  grid-row-start: 2;
}

.image-container {
  grid-row-start: 2;
}

@media screen and (max-width: 480px) {
  .subpage-section .content {
    grid-template-columns: 1fr !important;
    grid-template-rows: auto auto auto;
  }

  .subpage-title {
    grid-column-start: 1 !important;
    text-align: center;
  }

  .subpage-section {
    padding: 1.5rem;
  }

  .image-container {
    grid-row-start: 2;
  }

  .image-container img {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }

  .text-container {
    grid-row-start: 3;
  }
}
