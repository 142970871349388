.subpage {
  display: flex;
  flex-direction: column;
  padding: 3rem 0;
  padding-bottom: 1rem;
  align-items: center;
  position: relative;
}

.subpage .detect-visibility {
  position: absolute;
  top: 25%;
}

.subpage #join-button {
  margin-top: 1.5rem;
}

.container p {
  line-height: 1.5rem;
}

.container img {
  float: left;
  margin-right: 1.25rem;
  margin-bottom: 0;
  border-radius: 0.5rem;
}

.subpage-title {
  text-transform: uppercase;
  margin: 0;
  margin-bottom: 0.25rem;
  text-align: center;
}

.container img#text {
  width: 20rem;
}

@media screen and (max-width: 600px) {
  .subpage {
    padding: 1.5rem;
  }

  .container img#text {
    width: 95%;
    margin-left: 2.5%;
    float: unset;
  }

  .container {
    margin: 0;
  }
}
