body[data-modal-open="true"] {
  overflow: hidden;
}

.nav-bar {
  display: flex;
  height: 6rem;
  align-items: center;
  justify-content: center;
  background-color: var(--theme-color-secondary);
  margin-bottom: 1rem;
  position: relative;
}

.nav-bar .link {
  text-decoration: none;
  color: var(--theme-color-primary);
  text-transform: uppercase;
}

.nav-bar .list {
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  height: inherit;
  align-items: center;
}

.nav-bar .item {
  list-style: none;
  display: inline-block;
  padding: 0 0.75rem;
  margin: 0;
}

.nav-bar .item[data-current="true"] {
  font-weight: bold;
  border-bottom: 0.2rem solid var(--theme-color-primary);
}

.nav-bar .item:hover .link {
  color: white;
}

button {
  display: none;
}

.nav-bar #mobile {
  display: block;
  text-align: center;
  overflow-y: auto;
  position: absolute;
  top: 5rem;
  left: 0;
  width: 100%;
  height: calc(100vh - 5rem);
  background: white;
  z-index: 1;
}

.nav-bar img {
  height: 2.5rem;
}

.nav-bar #menu {
  font-size: 2.5rem;
  align-self: end;
}

.nav-bar #menu-button {
    justify-content: flex-end;
}
.top-button {
    width: inherit;
    display: flex;
    justify-content: space-between;
}

@media screen and (max-width: 480px) {
  .nav-bar {
    flex-direction: column;
    height: 5rem;
    width: 100%;
    background-color: rgb(240, 240, 240);
  }

  .nav-bar .list {
    display: none;
    justify-content: unset;
    font-size: 1.5rem;
  }

  .nav-bar .item {
    padding: 0;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
  }

  .nav-bar .item:hover .link {
    color: var(--theme-color-secondary);
  }

  button {
    border: none;
    padding: 0.75rem;
    background-color: rgb(240, 240, 240);
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}
