@font-face {
  font-family: "Century Gothic";
  src: local("Century Gothic"), url("/public/fonts/Century Gothic.otf") format("opentype");
}

:root {
  --theme-color-primary: #295487;
  --theme-color-secondary: #a2bcde;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body * {
  font-family: "Century Gothic", "Arial", "Helvetica", sans-serif;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-flow: column;
  justify-items: self-start;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.main-container #join-button {
  justify-content: center;
}

.main-container {
  margin: 0 16rem;
  max-width: 60rem;
  background-color: white;
  padding: 0 2rem;
  margin-bottom: 2rem !important;
  border-radius: 1rem;
  flex-grow: 1;
}

@media screen and (min-width: 92rem) {
  .main-container {
    margin: 0 auto;
  }
}

@media screen and (max-width: 1080px) {
  .main-container {
    margin: 0 9rem;
  }
}

@media screen and (max-width: 810px) {
  .main-container {
    margin: 0 2rem;
  }
}

@media screen and (max-width: 600px) {
  .main-container {
    margin: 0 1.5rem;
  }
}

@media screen and (max-width: 512px) {
  .main-container {
    margin: 0 0.75rem;
    padding: 0.5rem;
  }

  .content {
    flex-flow: column;
  }
}
