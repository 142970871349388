.form-container {
  display: flex;
  justify-content: center;
}

.form-container form {
  width: 100%;
}

.text-input {
  all: unset;
  border-style: solid;
  border-width: 1px;
  margin: 0.5rem 0;
  padding: 0.5rem;
  border-radius: 5px;
  width: calc(100% - 1rem);
}

.row {
  display: flex;
  justify-content: center;
}

.entry {
  display: flex;
  flex-direction: column;
  margin: 0.5rem 1rem;
  flex: 1;
}

.selection {
  display: flex;
  margin: 1rem;
  flex-direction: column;
}

.checkbox {
  display: flex;
  margin: 1rem;
  flex-direction: row;
}

.options input {
  margin-right: 1rem;
  margin-left: 0.5rem;
  margin-top: 0.5rem;
}

.submit-button {
  background-color: var(--theme-color-secondary);
  color: var(--theme-color-primary);
  border: none;
  border-radius: 1rem;
  width: 25%;
  margin: 1rem 0;
  height: 2rem;
  font-size: 1.25rem;
  min-width: 6.25rem;
}

.submit-button:hover,
.submit-button:active,
.submit-button:focus {
  color: var(--theme-color-secondary);
  background-color: var(--theme-color-primary);
}

em {
  color: #222;
  font-weight: bold;
}
