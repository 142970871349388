.learn-more {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: auto;
  font-weight: bold;
}

.learn-more a {
  text-decoration: none;
  color: var(--theme-color-primary);
  border-radius: 2rem;
  background-color: var(--theme-color-secondary);
  padding: 0.75rem 1.25rem;
  font-size: 1rem;
  text-align: center;
}

.learn-more a:hover,
.learn-more a:active,
.learn-more a:focus {
  color: var(--theme-color-secondary);
  background-color: var(--theme-color-primary);
}
